<template>
<div>
	<bar title="true"/>

  <v-content>
    <message/>
    <v-container>
    </v-container>
  </v-content>

  <footer-button ref="footerButton" redirection="/refresh" name="Intentar de nuevo"/>

</div>
</template>

<script>

import bar from '../components/appComponents/Bar.vue'
import message from '../components/appComponents/Message.vue'
import footerButton from '../components/appComponents/FooterButton.vue'

export default {
  name: 'tryAgain',
  components: {
    bar,
    message,
    'footer-button' : footerButton
  },
  mounted(){
    this.$root.$emit('changeMessage', { show : true , text : 'En este momento no es posible acceder a poemsandlearn con normalidad, por favor espere unos minutos e inténtelo de nuevo' } )
    this.$refs.footerButton.enable()
  }
}
</script>

<style>

</style>
